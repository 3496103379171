<template>
  <div class="modal">
    <img src="../assets/close.png" class="close" @click="closeModal">
    <div class="opacity"></div>

    <div class="details">
      <dv-border-box-8 :reverse="true">
        <div class="title">
          <span v-if="sederDetails">家宴信息</span>
        </div>

        <div class="item1">
          <div class="item1-1" style="width:35%">
            <img src="../assets/home.png" />
            <span v-if="senderDetails">家宴中心：{{ senderDetails.name }}<i></i></span>
            <i></i>
          </div>
          <div class="item1-1">
            <img src="../assets/clock.png" />
            <span v-if="cookInfo">乡厨信息：{{ cookInfo.name }}<i></i></span>
          </div>
          <div class="item1-1">
            <img src="../assets/fileText.png" />
            <span v-if="sederDetails">联系方式：{{ sederDetails.phone }}<i></i></span>
          </div>
          <div class="item1-1">
            <span v-if="cookInfo">乡厨当前总分：{{ cookInfo.sederScore + cookInfo.deductScore +
              cookInfo.addScore + cookInfo.basicsScore + cookInfo.screeningScore + cookInfo.fixedScore
              }}分<i></i></span>
          </div>
        </div>

        <div class="btn">
          <div class="btn1" v-for="(item, index) in btnList" :key="index" :class="hoverIndex === index ? 'hover' : ''"
            @click="btnFun(index)">{{ item }}
          </div>
        </div>

        <div class="content" v-if="hoverIndex === 0">
          <!-- <div class="con-left" v-if="imgList"> -->
          <!-- <el-carousel @change="changeFun" :interval="5000" arrow="always"
                            style="margin-left:50px;border-radius: 30px;" height="400px" :initial-index="0" ref="mysweiper"
                            indicator-position="none">
                            <el-carousel-item v-for="item, index in [senderDetails.feastImg]" :key="index">
                                <img :src="item" style="margin:0 auto" />
                            </el-carousel-item>
                        </el-carousel> -->
          <!-- <div class="tips">{{ imgRemark[imgIndex] }}</div> -->
          <!-- </div> -->
          <div class="con-right" style="width:90%;display: flex;align-items: center;justify-content: center;">
            <div class="con-right1" v-if="sederDetails" style="width:80%">
              <dv-border-box-13>
                <div style="display: flex;justify-content: space-between;">
                  <div><span>家宴中心：</span>{{ senderDetails.name }}</div>
                  <div><span>聚餐桌数：</span>{{ sederDetails.tableNumber }}桌
                  </div>
                </div>
                <div style="display: flex;justify-content: space-between;">
                  <div><span>聚餐人数：</span>{{ sederDetails.dinnerPeopleNumber
                    }}人</div>
                  <div><span>厨师承办团队：</span>{{ cookInfo.name }}</div>
                </div>
                <div style="display: flex;justify-content: space-between;">

                  <div><span>承诺书签订：</span>{{ sederDetails.organizersSignature ? '已签订' : '未签订'
                    }}
                  </div>
                  <div><span>存在高风险食品：</span>{{
                    sederDetails.highRiskFoods === '0' ? '不存在' : '存在'
                    }}<span v-if="sederDetails.highRiskFoods !== '0'">{{
                      sederDetails.riskDescriptions
                      }}</span>
                  </div>
                </div>

              </dv-border-box-13>
            </div>
          </div>
        </div>
        <div class="content" v-if="hoverIndex === 1">
          <div class="con-left" style="width:700px;" v-if="imgList && imgList.length">
            <el-carousel @change="changeFun" loop :interval="5000" arrow="always"
              style="margin-left:50px;border-radius: 30px;" height="400px" :initial-index="0" ref="mysweiper"
              indicator-position="none">
              <el-carousel-item v-for="item, index in imgList" :key="index">
                <img :src="item" style="margin:0 auto" />
              </el-carousel-item>
            </el-carousel>
            <div class="tips">{{ checkList[imgIndex].name }}：{{ imgRemark[imgIndex] }}</div>
          </div>
          <div class="con-right" :style="imgList && imgList.length ? 'width:400px' : 'width:700px'">
            <dv-scroll-board v-if="checkList" :config="configListTop"
              :style="imgList && imgList.length ? 'width:400px;height:540px' : 'width:700px;height:540px'" />
          </div>
        </div>
        <div class="content content2" v-if="hoverIndex === 2">
          <div class="score-item score-item3" v-if="scoreAllList.length !== 0">
            <div class="score-1" :class="scoreIndex === 1 ? 'scoreHover' : ''" @click="scoreFun(1)">加分项</div>
            <div class="score-1" :class="scoreIndex === 2 ? 'scoreHover' : ''" @click="scoreFun(2)">扣分项</div>
            <div class="score-1" :class="scoreIndex === 3 ? 'scoreHover' : ''" @click="scoreFun(3)">家宴评价</div>
            <div class="score-3" style="width:50%;text-align: right;">得分：{{
              scoreIndex === 1 ? subScores : scoreIndex === 2 ?
              addScores : scoreIndex === 3 ? sederScores : '--' }}分</div>
          </div>

          <div class="score-item score-item3" style="margin-top:35px;" v-if="scoreAllList.length !== 0">
            <div class="score-1">{{ scoreIndex === 3 ? '名称' : '序号' }}</div>
            <div class="score-1" style="width: 1100px;text-align: left">{{ scoreIndex === 1 ? '加分项' : scoreIndex
              === 2 ? '扣分项' :
              '家宴评价' }}</div>
            <div class="score-1" style="text-align: right;">{{ scoreIndex === 1 ? '加分' : scoreIndex === 2 ? '扣分'
              : '' }}</div>
            <!-- <div class="score-3">不扣分</div> -->
          </div>
          <span style="display: block;
    height: 590px;
    overflow: auto;" v-if="scoreAllList.length !== 0 && scoreIndex !== 3">
            <div class="score-item" v-for="(item, index) in scoreAllList" :key="index">
              <div class="score-1">{{ index + 1 }}</div>
              <div class="score-2">{{ item.rulesName }}
              </div>
              <div class="score-3">{{ item.type === '1' ? '' : '+' }}{{ item.scrollShow }}</div>
            </div>
          </span>
          <div class="score-item" v-if="scoreIndex === 3">
            <div class="score-2">
              <span style="color: #fff;">菜品评价：</span>
              <img v-for="item in  scoreStar1.score" :key="item" :src="hover" />
              <img :src="hover" v-for="item in (5 - scoreStar.score)" :key="item" />

            </div>
          </div>
          <div class="score-item" v-if="scoreIndex === 3">
            <div class="score-2">
              <span style="color: #fff;">服务评价：</span>
              <img :src="hover" v-for="item in  scoreStar.score" :key="item" />
              <img :src="hover" v-for="item in (5 - scoreStar.score)" :key="item" />

            </div>
          </div>
        </div>
      </dv-border-box-8>
    </div>
    <el-image ref="imgView" style="width: 100px; height: 100px;opacity:0" :src="url" :preview-src-list="srcList">
    </el-image>
  </div>
</template>
<script>
  import { systemCook, sederInfo, registrationCheck, scoreList2 } from "@/api/login";
  export default {
    props: ['sederDetails'],
    data() {
      return {
        hover: require('../assets/hom1.png'),
        greys: require('../assets/hom2.png'),
        configListTop: {
          header: ['管理项目', '符合要求'],
          data: [],
          headerBGC: 'none',
          evenRowBGC: 'none',
          rowNum: 7,
          align: ['center', 'center']
        },
        checkList: null,
        scoreIndex: 1,
        hoverIndex: 0,
        btnList: ['聚餐信息', '现场指导', '厨师评价'],
        url: 'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg',
        srcList: [
          'https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg',
          'https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg'
        ],
        cookInfo: {},
        senderDetails: {},
        ruleListAdd: [],
        ruleListSub: [],
        scoreAllList: [],
        scoreStar: {},
        imgRemark: [],
        imgList: null,
        imgIndex: 0,
        scoreStar1: {},
        ruleListNot: [],
        addScores: 0,
        subScores: 0,
        sederScores: 0
      }
    },
    mounted() {
      this.systemCook()
      this.sederInfo()
      this.registrationCheck()
      this.scoreList()
    },
    methods: {
      changeFun(index) {
        this.imgIndex = index
      },
      // 根据家宴检查id查询检查详情
      registrationCheck() {
        this.imgList = []
        this.imgRemark = []
        registrationCheck(this.sederDetails.checkId).then(res => {
          this.checkDetails = res.data
          this.checkList = []
          for (let idxA in res.data.registrationCheck) {
            if (res.data.registrationCheck[idxA] && idxA !== 'createTime' && idxA !== 'id' && idxA !== 'sederId' && idxA !== 'sederRegistrationId') {
              const nameFun = function (idxA) {
                return idxA === 'scenePeopleManagement' ? '人员管理' : idxA ===
                  'sceneWorkplace' ? '场所环境' : idxA ===
                    'sceneFacility' ? '设备设施' : idxA ===
                      'scenePurchaseStorage' ? '采购储存' : idxA ===
                        'sceneProcessMaking' ? '加工制作' : idxA ===
                          'sceneDisinfectTableware' ? '餐具消毒' : idxA ===
                            'sceneFoodSample' ? '食品留样' : ''
              }
              this.checkList.push({ name: nameFun(idxA), val: res.data.registrationCheck[idxA] === '1' ? '符合' : '不符合' })
            }

          }
          this.configListTop.data = this.checkList.map(item => {
            return [item.name, item.val]
          })

          for (let i in res.data.registrationCheckExtend) {

            if (res.data.registrationCheckExtend[i] && i.indexOf('Img') !== -1) {
              this.imgList.push(res.data.registrationCheckExtend[i])
            }
            if (res.data.registrationCheckExtend[i] && i.indexOf('Remark') !== -1) {
              this.imgRemark.push(res.data.registrationCheckExtend[i])
            }
          }
        })
      },
      // 查询厨师评分列表
      scoreList() {
        this.ruleList = []
        scoreList2({ cookId: this.sederDetails.cookId, sederId: this.sederDetails.sederId, sederRegistrationId: this.sederDetails.id }).then(res => {
          //    this.ruleList = 
          this.scoreStar = res.data[res.data.length - 1]
          this.scoreStar1 = res.data[res.data.length - 2]
          for (let i = 0; i < res.data.length; i++) {
            const item = res.data[i]
            // for (let j = 0; j < item.children.length; j++) {
            //     const itemA = item.children[j]
            // if (itemA.rulesId || itemA.rulesId === 0) {
            if (item.type === '1') {
              this.addScores = this.addScores + item.score
              this.ruleListAdd.push({
                // scrollShow: item.rulesId || item.rulesId === 0 ? item.scoreChoices : 0,
                scrollShow: item.score,
                scoreName: item.name,
                type: item.type,
                rulesName: item.name,
                scoreChoices: item.scoreChoices
              })
            } else if (item.type === '2') {
              this.subScores = this.subScores + item.score
              this.ruleListSub.push({
                scrollShow: item.score,
                scoreName: item.name,
                type: item.type,
                rulesName: item.name,
                scoreChoices: item.scoreChoices
              })
            } else {
              this.sederScores = this.sederScores + item.score
            }
            // }
            // else {
            //     if (itemA.type === '1') {
            //         this.ruleListNot.push({
            //             scrollShow: itemA.rulesId || itemA.rulesId === 0 ? itemA.scoreChoices : 0,
            //             scoreName: itemA.name,
            //             type: itemA.type,
            //             rulesName: item.name,
            //             scoreChoices: itemA.scoreChoices
            //         })
            //     } else {
            //         this.ruleListNot.push({
            //             scrollShow: itemA.rulesId || itemA.rulesId === 0 ? itemA.scoreChoices : 0,
            //             scoreName: itemA.name,
            //             type: itemA.type,
            //             rulesName: item.name,
            //             scoreChoices: itemA.scoreChoices
            //         })
            //     }
            // }
            // }

          }
          this.scoreAllList = this.ruleListSub
        })
      },
      // 厨师详情
      systemCook() {
        systemCook(this.sederDetails.cookId).then((res) => {
          this.cookInfo = res.data
        })
      },
      // 家宴详情
      sederInfo() {
        sederInfo(this.sederDetails.sederId).then((res) => {
          this.senderDetails = res.data
        })
      },
      // 关闭弹窗
      closeModal() {
        this.$emit('closeModal')
      },
      scoreFun(index) {
        this.scoreIndex = index
        if (index === 1) this.scoreAllList = this.ruleListSub
        if (index === 2) this.scoreAllList = this.ruleListAdd
      },
      // 大图浏览
      priview() {
        this.$refs.imgView.clickHandler()
      },
      btnFun(index) {
        this.hoverIndex = index
      }
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep .el-carousel__item {
    display: flex;
  }

  ::v-deep .el-carousel__arrow {
    font-size: 70px;
  }

  ::v-deep .header-item,
  ::v-deep .ceil {
    font-size: 20px;
  }

  .score-item {
    padding: 19px;
    width: 100% !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.28);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    color: rgba(153, 179, 200, 1);
    cursor: pointer;
  }

  .scoreHover {
    font-size: 31px;
    color: #fff;
  }

  .score-1 {
    width: 200px;
    text-align: center;
  }

  .score-2 {
    width: 1100px;
    line-height: 1.5;
    font-size: 27px;
    color: #fff;

    img {
      width: 30px;
      height: 30px;
      margin-left: 10px;
    }
  }

  .score-3 {
    width: 70px;
    text-align: center;
  }

  .score-item3 {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .btn {
    margin: 50px auto;
    display: flex;
    align-items: center;
    // justify-content: center;
    cursor: pointer;
    margin-left: 150px;

    .btn1 {
      margin-left: 50px;
      width: 235px;
      text-align: center;
      height: 52px;
      line-height: 52px;
      font-size: 23px;
      background: linear-gradient(180deg, rgba(25, 30, 38, 0) 0%, rgba(81, 93, 111, 1) 100%);
      color: rgba(176, 216, 245, 1);
      border: 1px solid rgba(182, 182, 182, 1);
      border-radius: 10px;
    }

    .hover {
      color: #fff;
      font-size: 25px;
      font-weight: bold;
    }

  }

  .con-right {
    display: flex;
  }

  .con-right1-tit {
    font-size: 22px;
    color: rgba(176, 216, 245, 1);
    margin-top: 0;
  }

  .con-right1 span {
    // width: 214px;
    text-align: left;
    display: inline-block;
    margin-right: 10px;
    font-size: 22px;
    font-weight: normal;
    margin-left: 20px;
  }

  .con-right1 div {
    margin-right: 10px;
    font-size: 25px;
    font-weight: bold;
    color: #fff;
    margin-top: 22px;
    min-height: 80px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
  }

  .content2 {
    width: 80%;
    margin: 0 auto;
    display: block;
  }

  .con-right2 {
    margin-left: 139px;
  }

  .tips {
    width: 77%;
    background: linear-gradient(180deg, rgba(63, 4, 4, 1) 0%, rgba(25, 30, 38, 0) 100%);
    color: #fff;
    padding-left: 3%;
    height: 70px;
    line-height: 70px;
    border-radius: 3px;
    margin-left: 20%;
    margin-top: 20px;
    font-size: 30px;
  }

  .content>div {
    width: 900px;
  }

  .con-right {
    margin-left: 50px;
  }

  .con-right1 {
    width: 400px;
  }

  .opacity {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.8;
    z-index: 555555;
  }


  // .el-carousel__item {
  //     width: 80%;
  //     border-radius: 30px;
  //     margin-left: 20%;
  // }

  .close {
    position: fixed;
    top: 3%;
    right: 5%;
    width: 50px;
    height: auto;
    z-index: 5566666666;
    cursor: pointer;
  }

  .details {
    width: 70%;
    height: 95%;
    position: fixed;
    top: 2.5%;
    left: 15%;
    background: url("../assets/bg1.png") no-repeat center;
    background-size: 100% 100%;
    // border: 2px solid rgba(171, 171, 171, 1);
    border-radius: 16px;
    z-index: 55553444444;
  }

  .item1 {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    .item1-1 {
      width: 25%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #fff;

      img {
        width: 26px;
        margin-right: 10px;
      }

      span {

        position: relative;

        i {
          position: absolute;
          width: 120%;
          height: 50%;
          background: linear-gradient(90deg, rgba(23, 212, 255, 0.3) 0%, rgba(0, 0, 0, 0.3) 100%);
          left: 0;
          bottom: 0;

        }
      }
    }
  }

  .title {
    position: relative;
    color: #fff;
    font-size: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    font-weight: bold;

    img {
      height: 100%;
      width: auto;
      position: absolute;
      left: 0;
      top: 0;
    }

    span {
      display: inline-block;
      font-weight: bold;
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
</style>