<template>
  <!-- <dv-loading v-if="!totalData">加载中...</dv-loading> -->
  <div id="index" ref="appRef">
    <div class="bg">
      <!-- <dv-loading v-if="!totalData">加载中...</dv-loading> -->
      <div class="host-body">
        <div class="d-flex jc-center">
          <dv-decoration-10 class="dv-dec-10" />
          <div class="d-flex jc-center">
            <dv-decoration-8 class="dv-dec-8" :color="decorationColor" />
            <div class="title">
              <span class="title-text" @click="modal = true">浔食安智慧监管平台</span>
              <dv-decoration-1 class="dv-dec-6" :reverse="true" :color="['#50e3c2', '#67a1e5']" />
            </div>
            <dv-decoration-8 class="dv-dec-8" :reverse="true" :color="decorationColor" />
          </div>
          <dv-decoration-10 class="dv-dec-10-s" />
        </div>

        <!-- 第二行 -->
        <div class="d-flex jc-between px-2">
          <div class="d-flex aside-width">
            <div class="react-left ml-7 react-l-s">
              <span class="react-left" style="top:-48px;cursor:pointer;" @click="go">综合查一次</span>
              <span class="text">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
            </div>

          </div>
          <div class="d-flex aside-width">
            <div class="react-right bg-color-blue mr-7">
              <span class="text fw-b">最近一次数据更新时间：{{ dateYear1 }} {{ dateWeek1 }} {{ dateDay1 }}</span>
            </div>
          </div>
        </div>

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="content-box">
            <div class="top">
              <dv-border-box-8>
                <div class="titles">
                  <span>总体概况</span>
                  <dv-decoration-5 style="width:400px;height:40px;margin-top:30px;" />

                </div>
                <div class="top-1">
                  <div class="left">
                    <dv-border-box-13 v-if="totalData">家宴中心：<b>{{ totalData.sederCount
                        }}</b></dv-border-box-13>


                  </div>
                  <div class="left">
                    <dv-border-box-13 v-if="totalData">厨师团队：<b>{{ totalData.cookCount
                        }}</b></dv-border-box-13>


                  </div>
                  <div class="left">
                    <dv-border-box-13 v-if="totalData">阳光厨房：<b>{{ totalData.sunshineKitchenCount
                        }}</b></dv-border-box-13>
                  </div>
                </div>

                <div class="top-2">
                  <div v-if="totalData" style="width:270px;height:130px">
                    <dv-border-box-12>
                      <div class="item-item-1" v-for="item, index in config.data" :key="index">
                        {{ item.name }}：<b>{{
                          item.value }}</b>
                      </div>
                    </dv-border-box-12>
                  </div>
                  <!-- <dv-capsule-chart v-if="totalData" :config="config" style="width:270px;height:130px" /> -->
                  <div class="circle" style="width:180px">
                    <div style="   width:100%; text-align: center;padding-top: 11px;">现场指导/已备案数比率</div>
                    <dv-water-level-pond v-if="totalData && !isLoading" :config="configWater"
                      style="width:120px;height:120px;margin:10px 30px" />
                  </div>

                </div>
                <div style="display: flex;
                                align-items: center;">
                  <div>
                    <div class="top2-tit">
                      <dv-decoration-7 style="width:200px;height:30px;">健康证状态</dv-decoration-7>
                      <!-- <dv-decoration-2 style="width:200px;height:5px;" />
                                            <p class="info">健康证状态</p> -->
                    </div>
                    <div class="top-3">
                      <dv-active-ring-chart v-if="totalData && config4Value !== 0 && !isLoading" :config="config4"
                        style="width: 950%;height: 222px;margin-top: -0;" />
                      <dv-active-ring-chart v-if="!isLoading && config4Value === 0" :config="config4Empty"
                        style="width: 95%;height: 222px;margin-top: -0;" />
                    </div>
                    <div class="points" style="display: flex;align-items: center;justify-content: center;">
                      <div class="points1" v-for="(item, index) in config4.data" style="margin-left:5px;" :key="index">
                        <div class="radius" :style="'background:' + color[index]"></div>
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="top2-tit">
                      <dv-decoration-7 style="width:200px;height:30px;">放心厨房</dv-decoration-7>
                      <!-- <dv-decoration-2 style="width:200px;height:5px;" />
                                            <p class="info">放心厨房</p> -->
                    </div>
                    <div class="top-3">
                      <dv-active-ring-chart v-if="totalData && config5Value !== 0  && !isLoading" :config="config5"
                        style="width: 95%;height: 222px;margin-top: -0;" />
                      <dv-active-ring-chart v-if="config5Value === 0  && !isLoading" :config="config4Empty"
                        style="width: 95%;height: 222px;margin-top: -0;" />
                    </div>
                    <div class="points" style="display: flex;align-items: center;justify-content: center">
                      <div class="points1" style="margin-left:5px;" v-for="(item, index) in config5.data" :key="index">
                        <div class="radius" :style="'background:' + color[index]"></div>
                        <span>{{ item.name }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </dv-border-box-8>
            </div>
            <div class="bottom" v-if="totalData">
              <dv-border-box-8>
                <div class="titles">
                  <span>每月趋势</span>
                  <dv-decoration-5 style="width:400px;height:40px;margin-top:30px;" />

                </div>
                <div class="line" id="line"></div>
              </dv-border-box-8>
            </div>
          </div>
          <div class="content-box">
            <div>
              <dv-border-box-8>
                <el-select style="margin-top:10px;margin-left:10px" placeholder="请选择" v-model='regionValue'
                  @change="regionChange">
                  <el-option v-for="item in streetAry" :key="item" :label="item" :value="item">
                  </el-option>
                </el-select>
                <el-date-picker @change="timeChange" style="margin-top:8px;margin-left:10px" type="daterange"
                  v-model="timePicker" value-format="yyyy-MM-dd HH:mm:ss" range-separator="至" start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>

                <div class="map" id="map" v-if="regionValue && regionValue !== '全部'">
                </div>
                <centerLeft2 v-else @getRegion="regionChange" />
              </dv-border-box-8>
            </div>

            <div>
              <dv-border-box-8>
                <div class="titList">
                  <p v-for="(item, index) in titList" :key="index" :class="hoverIndex === index ? 'hover' : ''"
                    @click="titFun(index)">
                    <dv-border-box-1>{{ item }}</dv-border-box-1>
                  </p>
                </div>
              </dv-border-box-8>
            </div>
          </div>

          <!-- 右 -->
          <div class="content-box">
            <div class="right-top">
              <dv-border-box-8>
                <div class="list-tit">
                  <span class="decoration-8-span" @click="btnFun(0)"
                    :class="hoverIndexBtn === 0 ? 'hover' : ''">已现场指导</span>
                  <span @click="btnFun(1)" class="decoration-8-span decoration-8-span1"
                    :class="hoverIndexBtn === 1 ? 'hover' : ''">已备案登记</span>
                  <dv-decoration-8 style="width:80%;height:50px;margin-top:20px;" />
                </div>
                <div class="list">
                  <dv-scroll-board @mouseover="mouseoverFun" v-if="list" @click="tableFun" :config="configListTop"
                    style="width:600px;height:380px" />
                  <el-pagination small :current-page.sync="pageNum" @current-change="currentChange" :page-size="6"
                    layout="total, prev, pager, next" :total="total" style="float:right;margin-right:30px;">
                  </el-pagination>
                </div>
              </dv-border-box-8>
            </div>
            <div class="right-bottom">
              <dv-border-box-8>

                <div class="list-tit">
                  <span class="tit-botttom">乡厨团队积分榜</span>
                  <dv-decoration-5 style="width:90%;height:40px;margin-top:30px;" />
                </div>
                <div class="list">
                  <dv-scroll-board v-if="listCook" :config="configList" style="width:500px;height:240px" />
                  <el-pagination small :current-page.sync="pageNum1" @current-change="currentChange1" :page-size="4"
                    layout="total, prev, pager, next" :total="total1" style="float:right;margin-right:30px;">
                  </el-pagination>
                </div>
              </dv-border-box-8>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div clas="modals" v-if="modal">
      <modal @closeModal="closeModal" :checkDetails="checkDetails" :fellowPersonnelList="fellowPersonnelList" />
    </div>
    <div class="modal" v-if="shopImg">
      <img src="../assets/close.png" class="close" @click="shopImg = null">
      <img :src="shopImg" class="shopImg" />
      <div class="opacity"></div>
    </div>

    <div clas="modals" v-if="modalFamily">
      <modalFamily v-if="sederDetails" :sederDetails="sederDetails" @closeModal="closeModal" />
    </div>
  </div>
</template>

<script>
  import { sederStatistics, registrationList, sederList, shopCheck, cookList, systemSeder } from "@/api/login";
  import drawMixin from "../utils/drawMixin";
  import { formatTime } from '../utils/index.js'
  import centerLeft2 from './centerLeft2'
  import modal from './modal'
  import modalFamily from './modalFamily'
  import * as echarts from 'echarts';
  import AMapLoader from '@amap/amap-jsapi-loader';
  export default {
    mixins: [drawMixin],
    data() {
      return {
        total: 0,
        pageNum: 1,
        pageNum1: 1,
        total1: 0,
        config4Empty: {
          digitalFlopStyle: {
            fontSize: 0,
            fill: '#fff'
          },
          lineWidth: 15,
          showOriginValue: true,
          data: [{
            name: '',
            value: 1
          }],
          color: ['#dddddd']
        },
        modalFamily: false,
        configListTop: {
          header: ['序号', '家宴类型', '承办方', '家宴中心', '乡厨', '承办日期'],
          columnWidth: [40, 80, 70, 230, 80, 110],
          data: [],
          rowNum: 6,
          headerBGC: 'none',
          carousel: 'page',
          fontSize: 8,
          waitTime: 3000,
          evenRowBGC: 'none',
          align: ['center', 'center', 'center', 'center', 'center', 'center']
        },
        hoverIndexBtn: 0,
        configList: {
          header: ['排名', '厨师团队', '得分'],
          columnWidth: [60, 530, 60],
          data: [
          ],
          headerBGC: 'none',
          evenRowBGC: 'none',
          align: ['center', 'center', 'center']
        },
        config5Value: 0,
        config4Value: 0,
        hoverIndex: 2,
        titList: ['决策统计', '监督检查', '农村家宴'],
        shopImg: null,
        fellowPersonnelList: [],
        regionValue: '全部',
        timePicker: [],
        totalData: null,
        checkDetails: {},
        streetAry: ['全部', '东迁街道', '南浔镇', '练市镇', '双林镇', '菱湖镇', '和孚镇', '善琏镇', '石淙镇', '千金镇', '旧馆街道'],
        details: {},
        doorsList: [],
        map: null,
        modal: false,
        config8: {},
        config9: {},
        color: ['#8ABDF3', '#4367DC', '#54DED7', '#EEE397', '#FAAD14', '#4FC1C9', '#546BFF'],
        configWater: {
          shape: 'round'
        },
        isLoading: false,
        config13: {
          number: [],
          content: '{nt}'
        },
        config3: {
          number: [],
          content: '{nt}'
        },
        config1: {
          number: [],
          content: '{nt}'
        },
        config10: {
          number: [],
          content: '{nt}'
        },
        config11: {
          number: [],
          content: '{nt}'
        },
        config12: {
          number: [],
          content: '{nt}'
        },
        config7: {
          digitalFlopStyle: {
            fontSize: 15,
            fill: '#fff'
          },
          lineWidth: 15
        },
        config6: {
          digitalFlopStyle: {
            fontSize: 15,
            fill: '#fff'
          },
          lineWidth: 15
        },
        config5: {
          digitalFlopStyle: {
            fontSize: 15,
            fill: '#fff'
          },
          lineWidth: 15,
          showOriginValue: true
        },
        config4: {
          digitalFlopStyle: {
            fontSize: 14,
            fill: '#fff'
          },
          lineWidth: 15,
          showOriginValue: true
        },
        config2: {
          digitalFlopStyle: {
            fontSize: 14,
            fill: '#fff'
          },
          data: [{ name: '现场指导数', value: 20 }, { name: '已备案数', value: 80 }],
          lineWidth: 15,
          showOriginValue: true
        },
        listCook: null,
        timing: null,
        loading: true,
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        dateDay1: null,
        dateYear1: null,
        dateWeek1: null,
        weekday: ['周日', '周一', '周二', '周三', '周四', '周五', '周六'],
        decorationColor: ['#568aea', '#000000'],
        list: null,
        config: {
          data: [
            {
              name: '家宴场次'
            },
            {
              name: '家宴桌数'
            },
            {
              name: '聚餐人数'
            }
          ],
          colors: ['#1B7EF2', '#BD9000', '#58A600'],
          showValue: true,


        },
        chart2: null,
        mapCenter: null,
        sederDetails: null
      }
    },
    components: {
      centerLeft2,
      modal,
      modalFamily
    },
    mounted() {
      this.timeFn()
      this.cancelLoading()

    },
    created() {
      this.initAMap()
      // this.sederList('双林镇')
      const val = this.timePicker
      this.sederList(this.regionValue === '全部' ? '' : this.regionValue, (val && val.length) ? this.timePicker[0] : '', (val && val.length) ? this.timePicker[1] : '')
      this.audit(this.regionValue === '全部' ? '' : this.regionValue, (val && val.length) ? this.timePicker[0] : '', (val && val.length) ? this.timePicker[1] : '')
      this.cookList((val && val.length) ? this.timePicker[0] : '', (val && val.length) ? this.timePicker[1] : '', 1)
      this.times = setInterval(() => {
        this.sederList(this.regionValue === '全部' ? '' : this.regionValue, (val && val.length) ? this.timePicker[0] : '', (val && val.length) ? this.timePicker[1] : '')
        this.audit(this.regionValue === '全部' ? '' : this.regionValue, (val && val.length) ? this.timePicker[0] : '', (val && val.length) ? this.timePicker[1] : '')
        this.cookList((val && val.length) ? this.timePicker[0] : '', (val && val.length) ? this.timePicker[1] : '', 1)
      }, 60 * 5 * 1000)
    },
    beforeDestroy() {
      clearInterval(this.times)
      clearInterval(this.timing)
    },
    methods: {
      currentChange(current) {
        this.pageNum = current
        this.registrationList(this.regionValue, '', '', this.hoverIndexBtn + 1, current)
      },
      currentChange1(current) {
        const val = this.timePicker
        this.pageNum1 = current
        this.cookList((val && val.length) ? this.timePicker[0] : '', (val && val.length) ? this.timePicker[1] : '', current)
      },
      mouseoverFun() {
      },
      tableFun(row) {
        systemSeder(this.list[row.rowIndex].id).then((res) => {
          this.modalFamily = true
          this.sederDetails = res.data.sederRegistration
        })
      },
      returnCenter(name) {
        var center
        if (name === '南浔镇') center = { lng: 120.431469, lat: 30.872505 }
        else if (name === '练市镇') center = { lng: 120.429009, lat: 30.712698 }
        else if (name === '东迁街道') center = { lng: 120.426852, lat: 30.872926 }
        if (name === '双林镇') center = { lng: 120.336816, lat: 30.778398 }
        else if (name === '和孚镇') center = { lng: 120.16947, lat: 30.783195 }
        else if (name === '旧馆街道') center = { lng: 120.28861, lat: 30.841296 }
        if (name === '石淙镇') center = { lng: 120.273657, lat: 30.726523 }
        else if (name === '善琏镇') center = { lng: 120.324178, lat: 30.705508 }
        else if (name === '千金镇') center = { lng: 120.248064, lat: 30.682385 }
        if (name === '菱湖镇') center = { lng: 120.190923, lat: 30.718937 }
        return center
      },
      registrationList(val, endTime, startTime, isCheck, pageNum) {
        this.list = null
        registrationList(val === '全部' ? '' : val, endTime, startTime, isCheck === 2 ? '' : isCheck, pageNum).then(res => {
          this.list = res.rows
          this.configListTop.data = res.rows.map((item, index) => {
            return [(pageNum - 1) * 6 + index + 1, item.dinnerType === '0' ? "其他" : item.dinnerType === '1' ? '婚宴' : item.dinnerType === '2' ? '生日' : item.dinnerType === '3' ? '建房' : item.dinnerType === '4' ? "乔迁" : item.dinnerType === '5' ? '丧事' : '', item.organizers, item.sederName, item.cookName, item.sederRegistrationDateList.length ? item.sederRegistrationDateList[0].startTime : '--']
          })
          this.total = res.total
        })
      },
      cookList(endTime, startTime, pageNum) {
        this.listCook = null
        cookList(endTime, startTime, pageNum).then(res => {
          this.listCook = res.rows
          this.configList.data = res.rows.map((item, index) => {
            return [
              (pageNum - 1) * (4) + + index + 1, item.licenseName, item.sederScore + item.deductScore + item.addScore + item.basicsScore + item.screeningScore + item.fixedScore
            ]
          })
          this.total1 = res.total
        })
      },
      btnFun(index) {
        this.hoverIndexBtn = index
        this.registrationList(this.regionValue, '', '', index + 1, 1)
      },
      titFun(index) {
        if (index === 0) {
          this.$router.push({
            path: '/'
          })
        }
        if (index === 1) {
          this.$router.push({
            path: '/indexInspect'
          })
        }
      },
      initChart(sederRondaList, tableNumberList, dinnerPeopleNumberList) {
        var mothName = dinnerPeopleNumberList.map(item => {
          return item.type
        })
        const color = ['#1D8FFF', '#3AE3FF', '#56E73C']
        const code = ['家宴场次', '家宴桌数', '聚餐人数']
        var seriesData = [
          {
            data: sederRondaList.map(item => {
              return Number(item.value)
            }),
            name: '家宴场次',
            type: "line",
            lineStyle: {
              color: color[0],
            }
          },
          {
            data: tableNumberList.map(item => {
              return Number(item.value)
            }),
            type: "line",
            name: '家宴桌数',
            lineStyle: {
              color: color[1],
            },
          },
          {
            data: dinnerPeopleNumberList.map(item => {
              return Number(item.value)
            }),
            name: '聚餐人数',
            type: "line",
            lineStyle: {
              color: color[2],
            }
          }
        ]
        let option = {
          trigger: "axis",
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'cross',
              animation: true,
              label: {
                backgroundColor: '#505765'
              }
            }
          },
          legend: {
            left: "center",
            top: 25,
            itemWidth: 15,
            itemHeight: 10,
            data: code,
            textStyle: {
              color: "#fff",
              fontFamily: "Alibaba PuHuiTi",
              fontSize: 11,
              fontWeight: 400,
            }
          },
          backgroundColor: 'rgba(0, 0, 0, 0)',
          grid: {
            top: '20%',
            left: '0%',
            right: '5%',
            bottom: '4%',
            containLabel: true,
          },
          color: ['#1D8FFF', '#3AE3FF', '#56E73C'],

          xAxis: {
            type: 'category',
            data: mothName,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,
              textStyle: {
                color: '#fff', //X轴文字颜色
              },
            },
          },
          yAxis: [

            {
              type: 'value',
              nameTextStyle: {
                color: "#fff",
                fontFamily: "Alibaba PuHuiTi",
                fontSize: 14,
                fontWeight: 600,
              },
              nameGap: 30,  // 表现为上下位置
              axisLine: {
                show: true,
                lineStyle: {
                  color: '',
                }
              },
              axisTick: {
                show: false
              },
              axisLabel: {
                color: '#fff',
                fontSize: 14
              },
              splitLine: {
                show: true,
                lineStyle: {
                  color: '#fff',
                }
              },

            },

          ],
          series: seriesData
        };
        // 初始化echart
        this.$nextTick(() => {
          if (document.getElementById('line')) {
            this.chart2 = echarts.init(document.getElementById('line'))
            this.chart2.setOption(option, true)
          }
        })

      },
      timeChange(val) {
        if (val && val.length) {
          this.audit(this.regionValue === '全部' ? '' : this.regionValue, val[1], val[0])
        }
      },
      regionChange(vals) {
        this.totalData = {}
        this.doorsList = []
        this.regionValue = vals.name ? vals.name : vals
        this.mapCenter = vals.center ? vals.center : this.returnCenter(vals)
        const val = this.timePicker
        this.sederList(this.regionValue === '全部' ? '' : this.regionValue, (val && val.length) ? this.timePicker[1] : '', (val && val.length) ? this.timePicker[0] : '')
        this.audit(this.regionValue === '全部' ? '' : this.regionValue, (val && val.length) ? this.timePicker[1] : '', (val && val.length) ? this.timePicker[0] : '')
      },
      sederList(val) {

        sederList(val).then(res => {
          this.doorsList = res.data
          this.initAMap()
        })
      },
      shopCheck(shopId, cb) {
        shopCheck(shopId).then(res => {
          this.checkDetails = res.data
          this.fellowPersonnelList = res.data.fellowPersonnelList.map(item => {
            return item.nickName
          })
          cb()
        })
      },
      isFalse(data, cb) {
        var value = 0
        for (let i = 0; i < data.length; i++) {
          value += data[i].value
        }
        if (value === 0) {
          data = data.map(item => {
            item.value = 1
            return item
          })
          cb(data)
        }

      },
      // 获取综合检查
      audit(val, endTime, startTime) {
        this.isLoading = true
        this.config5Value = 0
        this.config4Value = 0
        this.dateDay1 = formatTime(new Date(), 'HH: mm: ss')
        this.dateYear1 = formatTime(new Date(), 'yyyy-MM-dd')
        this.dateWeek1 = this.weekday[new Date().getDay()]
        sederStatistics(val, endTime ? endTime.split(' ')[0] + ' 23:59:59' : '', startTime).then(res => {
          this.isLoading = false
          this.totalData = res.data
          this.registrationList(val, startTime, endTime ? endTime.split(' ')[0] + ' 23:59:59' : '', 1, 1)
          this.initChart(this.totalData.sederRondaList, this.totalData.tableNumberList, this.totalData.dinnerPeopleNumberList)
          this.config1.number = [res.data.sederCount ? res.data.sederCount : 0]
          this.config13.number = [res.data.sunshineKitchenCount ? res.data.sunshineKitchenCount : 0]
          this.config3.number = [res.data.sunshineKitchenCount ? res.data.sunshineKitchenCount : 0]
          this.config.data[0].value = res.data.sederRondaCount ? res.data.sederRondaCount : 0
          this.config.data[1].value = res.data.tableNumber ? res.data.tableNumber : 0
          this.config.data[2].value = res.data.dinnerPeopleNumber ? res.data.dinnerPeopleNumber : 0
          this.configWater.data = [res.data.sederCheckRatio]
          // this.config6.data = res.data.transientPopulationList.map((item) => {
          //     return {
          //         name: item.type,
          //         value: item.value
          //     }
          // })
          this.config4.data = res.data.healthCertificatesList.map((item) => {
            this.config4Value = this.config4Value + Number(item.value)
            return {
              name: item.type,
              value: item.value
            }
          })
          this.config5.data = res.data.assuredKitchenList.map((item) => {
            this.config5Value = this.config5Value + Number(item.value)
            return {
              name: item.type,
              value: item.value
            }
          })
          this.config8.value = res.data.fireFightingRate
          this.config9.value = res.data.enforcementCheckRate
          this.config10.number = [res.data.employeeCount ? res.data.employeeCount : 0]
          this.config11.number = [res.data.transientPopulationCount ? res.data.transientPopulationCount : 0]
          this.config12.number = [res.data.crimeActivityCount ? res.data.crimeActivityCount : 0]
        })
      },
      go() {
        this.$router.push({
          path: '/indexCheck'
        })
      },
      initAMap() {
        var markerList = []
        AMapLoader.load({
          key: 'ac02bea89e01b580212ded3b62b43682',  //设置您的key
          version: "2.0",
          plugins: ['AMap.ToolBar', 'AMap.Driving'],
          AMapUI: {
            version: "1.1",
            plugins: [],

          },
          Loca: {
            version: "2.0"
          },
        }).then((AMap) => {

          var infoWindow = new AMap.InfoWindow({
            isCustom: true,  //使用自定义窗体
            content: '',
            offset: new AMap.Pixel(120, -30)
          });
          window.map = new AMap.Map("map", {
            mapStyle: 'amap://styles/darkblue', //设置地图的显示样式
            viewMode: "3D",
            zoom: 17,
            center: [Number(this.mapCenter.lng), Number(this.mapCenter.lat)]
          });
          for (let i = 0; i < this.doorsList.length; i++) {
            const item = this.doorsList[i]
            // if (item.longitude) {
            const marker = new AMap.Marker({
              position: new AMap.LngLat(Number(item.longitude), Number(item.latitude))
            });
            marker.content = []
            marker.content.push("<div style='margin-bottom:-10px;opacity:1;width: 229px;height:82px;padding:10px;background: #092042 linear-gradient(225deg, #092348 0%, #0B3E79 100%); '><div style='display: flex;align-items:center;'><img onclick='imgFun(" + i + ")' src='" + item.feastImg + "' style='cursor: pointer;width:50px;height:50px;margin-right:20px;'/><b style='color:#00BAFF;font-size:16px;margin-top:-20px;'>" + item.name + "</b></div><p></div>");
            // marker.content.push("<div style='width:14px;height:24px;border-right:2px solid #0883FD;transform:rotate(-158deg)'></div>")
            marker.on('click', (e) => {
              infoWindow.open(window.map, marker.getPosition());
              infoWindow.setContent(e.target.content);
            });
            marker.emit('click', { target: marker });
            //实例化信息窗体
            window.handleBreak = (i) => {
              console.log(this.doorsList[i])
              systemSeder(this.doorsList[i].id).then((res) => {
                this.modalFamily = true
                this.sederDetails = res.data.sederRegistration
              })
              // this.shopCheck(this.doorsList[i].id, () => {
              //   this.modal = true
              //   this.$forceUpdate()
              // })

            }
            window.imgFun = (i) => {
              this.shopImg = this.doorsList[i].shopImg
            }
            markerList.push(marker)
            // }

          }

          // window.map = new AMap.Map("map", {
          //   mapStyle: 'amap://styles/darkblue', //设置地图的显示样式
          //   viewMode: "3D",
          //   zoom: 17,
          //   center: [Number(this.mapCenter.lng), Number(this.mapCenter.lat)]
          // });
          window.map.add(markerList)
          window.map.setFitView();
        }).catch(() => {
        })
      },
      // 关闭弹窗
      closeModal() {
        this.modal = false
        this.modalFamily = false
      },
      chart1() {
        let option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            top: '5%',
            left: 'center'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: ['40%', '70%'],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: '#fff',
                borderWidth: 2
              },
              label: {
                show: false,
                position: 'center'
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 40,
                  fontWeight: 'bold'
                }
              },
              labelLine: {
                show: false
              },
              data: [
                { value: 1048, name: 'Search Engine' },
                { value: 735, name: 'Direct' },
                { value: 580, name: 'Email' },
                { value: 484, name: 'Union Ads' },
                { value: 300, name: 'Video Ads' }
              ]
            }
          ]
        };
        // 初始化echart
        this.chart = echarts.init(document.getElementById('chart1'))
        this.chart.setOption(option, true)
      },
      timeFn() {
        this.timing = setInterval(() => {
          this.dateDay = formatTime(new Date(), 'HH: mm: ss')
          this.dateYear = formatTime(new Date(), 'yyyy-MM-dd')
          this.dateWeek = this.weekday[new Date().getDay()]
        }, 1000)
        // this.chart1()
      },
      cancelLoading() {
        setTimeout(() => {
          this.loading = false
        }, 500)
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import '../assets/scss/index.scss';

  ::v-deep .dv-scroll-board .header .header-item,
  ::v-deep .dv-scroll-board .rows .ceil {
    padding: 0;
  }

  ::v-deep .header-item,
  ::v-deep .ceil {
    font-size: 17px;
  }

  .right-top,
  .right-bottom {
    height: 50%;

    .list-tit {
      width: 100%;
      margin-left: 5%;
      height: 100px;
      padding-top: 20px;
      position: relative;

      span {
        width: 100%;
        text-align: center;
        display: inline-block;

      }

      .tit-botttom {
        position: absolute;
        display: inline-block;
        width: 90%;
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        top: 30px;
      }

      .decoration-8-span {
        position: absolute;
        width: 40% !important;
        text-align: center;
        font-size: 25px;
        font-weight: normal;
        left: 0;
        cursor: pointer;
      }

      .decoration-8-span1 {
        left: 40%;
      }

      .hover {
        font-weight: bold;
        font-size: 28px;
        color: #1a5cd7;
      }
    }
  }


  .right-top {
    height: 60%;
  }

  .right-bottom {
    height: 40%;
  }

  #line {
    height: 280px;
  }

  .titList {
    display: flex;
    justify-content: center;
    font-size: 25px;
    height: 220px;

    p {
      cursor: pointer;
      width: 200px;
      margin: 50px 0;
      margin-left: 3%;
      height: 100px;
      line-height: 100px;
      text-align: center;
      border-radius: 10px;
      // border: 2px solid #fff;
    }

    .hover {
      background: #1760AE;
      font-weight: bold;
      // border: 3px solid #1760AE;
    }
  }

  .opacity {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.8;
    z-index: 1;
  }

  .close {
    position: fixed;
    right: 50%;
    width: calc(100vw * 50 / 1920);
    height: auto;
    z-index: 557777666667;
    cursor: pointer;
    top: 28%;
    margin-right: -250px;
  }

  .shopImg {
    position: fixed;
    top: 30%;
    left: 50%;
    width: 400px;
    margin-left: -200px;
    z-index: 2;
  }

  #map {
    height: 680px;
  }

  .item-item-1 {
    font-size: 20px;
    padding-left: 30px;
    padding-top: 16px;

    b {
      font-size: 30px;
      color: #fff;
    }
  }

  ::v-deep .el-select .el-input__inner:focus {
    background: none;
    color: #fff;
    margin-right: 10px;
  }

  ::v-deep .el-input--medium .el-input__inner,
  ::v-deep .el-range-editor--medium.el-input__inner,
  ::v-deep .el-range-editor--medium .el-range-input,
  ::v-deep .el-range-editor--medium .el-range-separator {
    background: 0;
    color: #fff;
  }

  .top {
    height: 570px;
  }

  .bottom {
    height: 370px;
  }

  .top,
  .bottom {
    .titles {
      font-size: 22px;
      display: flex;
      align-items: center;
      font-weight: bold;
      position: relative;
      justify-content: center;

      span {
        position: absolute;
        left: 190px;
        font-size: 28px;
      }
    }

    .top-1 {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1%;

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        width: 30%;
        height: 80px;
        line-height: 80px;
        text-align: center;

        b {
          font-size: 15px;
        }
      }
    }

    .top-2 {
      display: flex;
      align-items: center;
      justify-content: center;

      .chart1 {
        width: 50%;
        height: 130px;
      }
    }

    .info {
      padding-top: 10px;
      margin-left: 10px;
      font-size: 22px;
      font-weight: bold;
    }

    .points1 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
      margin-bottom: 8px;

      .radius {
        width: 8px;
        height: 8px;
        background: red;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .top-3 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -40px;


    }

  }

  .bottom img {
    width: 80px;
    margin: 5px 10px;
  }

  .top-4 {
    height: 469px;
  }

  .center-bottom {
    height: 222px;

    .top-3 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -80px;
    }

    .top2-tit {
      font-size: 22px;
      display: flex;
      font-weight: bold;
      align-items: center;

      img {
        width: 80px;
        margin: 5px 10px;
      }
    }

    .info {
      padding-top: 10px;
      margin-left: 10px;
      font-size: 22px;
      font-weight: bold;
    }

    .points1 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      color: #fff;
      margin-bottom: 8px;

      .radius {
        width: 8px;
        height: 8px;
        background: red;
        border-radius: 50%;
        margin-right: 5px;
      }
    }

    .top-1 {
      margin: 20px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        margin-right: 10px;
        font-weight: bold;

      }
    }

    .bottom-tit {
      display: flex;
      justify-content: space-between;
    }

    .top3-tit2 {
      margin-right: 20px;
    }

    .width {
      width: 50%;
      text-align: center;
    }

    .top-3 {
      margin-top: -40px;
    }
  }

  .dv-dec-1 {
    position: relative;
    width: 100px;
    height: 20px;
    top: -3px;
  }

  .points2 {
    margin-top: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .points1 {
      margin-right: 5px;
      width: 40%;
    }
  }

  .hot {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1%;

    .hot-1 {
      width: 49%;
      height: 100px;

      .hot-1-1 {
        padding-top: 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .hot-1-3 {
          position: absolute;
          width: 100%;
          text-align: center;
          bottom: 20px;
          font-size: 19px;
        }

        .hot-1-2 {
          font-size: 19px;
        }
      }
    }
  }

  .pond {
    width: 100%;
    height: 90px;
    margin-top: 10px;
    display: flex;
    align-items: center;

    // justify-content: center;
    span {
      font-size: 18px;
      margin-right: 20px;
      margin-left: 10px;
    }
  }

  .pond2 {
    height: 160px;
  }

  .list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.5%;
    margin-top: 0px;
    cursor: pointer;

    .list-1 {
      width: 49%;
      height: 180px;

      div {
        padding: 5px;

        p {
          text-align: left;
          font-size: 18px;
          color: #fff;
          line-height: 1.3;
        }
      }

    }

    .list-2 {
      width: 60%
    }

    .list-3 {
      width: 39%;
    }

    .list-4 {
      width: 60%;
      height: 260px;
      margin-top: -30px;

      div {
        padding-left: 50px;
        padding-top: 20px;
        line-height: 2;
      }
    }
  }

  .list2 {
    height: 210px;
    justify-content: center;

    .list-1 {
      width: 60%;
    }
  }

  .list ::v-deep button,
  ::v-deep .el-pager,
  ::v-deep .number {
    background: rgb(0, 59, 81);
    color: #fff;
  }

  ::v-deep .header-item,
  ::v-deep .ceil {
    font-size: 18px;
  }

  ::v-deep .el-pagination {
    margin-top: 40px;
    position: absolute;
    right: 0;
    bottom: 10px;
  }

  ::v-deep .el-pagination__total {
    color: #fff;
    font-size: 25px;
  }
</style>